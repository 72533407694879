
module.exports = {
  firebase: {
    apiKey: "AIzaSyA-pKWNmhtk9Mzwp3WG5r1NS688hzu0GFk",
    authDomain: "ottawa-dashboard.firebaseapp.com",
    databaseURL: "https://ottawa-dashboard-default-rtdb.firebaseio.com",
    projectId: "ottawa-dashboard",
    storageBucket: "ottawa-dashboard.appspot.com",
    messagingSenderId: "935350513904",
    appId: "1:935350513904:web:4b9b41291e06d2eb291235",
    measurementId: "G-ZG91X2500E"
  }
}

